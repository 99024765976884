<ul>
    <li>
    <a href="javascript:;"
        on:click="{() => $sidebar.visible = !$sidebar.visible}">
        <i class="fa fa-bars"></i>
    </a>
    </li>
</ul>


<style>a {
  color: #fff;
  padding: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 0.25rem;
  border-width: 2px;
  font-size: 1.125rem;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL1NpZGVCYXJUb2dnbGUuc3ZlbHRlIiwiPG5vIHNvdXJjZT4iXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0k7RUNESixZQUFBO0VBQUEsZ0JBQUE7RUFBQSxxQkFBQTtFQUFBLHdCQUFBO0VBQUEsdUJBQUE7RUFBQSxrQkFBQTtFQUFBLG9CQUFBO0FER0kiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvU2lkZUJhclRvZ2dsZS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgICBhIHtcbiAgICAgICAgQGFwcGx5IHRleHQtd2hpdGUgcC0yIHB0LTEgcGItMSByb3VuZGVkIGJvcmRlci0yIHRleHQtbGc7XG4gICAgfVxuIixudWxsXX0= */</style>

<script type="text/javascript">
    import { sidebar } from '../store.js'
</script>