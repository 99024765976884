<div class="main-pic">
    <img class="slide" src="{ $sidebar.items[n]["slide"] }" alt="" />
</div>
<style>.main-pic {
  width: 100%;
  height: auto;
}

.slide {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL1NsaWRlLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDSTtFQUNJLFdBQVc7RUFDWCxZQUFZO0FBQ2hCOztBQUNBO0VBQ0ksV0FBVztFQUNYLFlBQVk7RUFDWixvQkFBaUI7S0FBakIsaUJBQWlCO0FBQ3JCIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL1NsaWRlLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuICAgIC5tYWluLXBpYyB7XG4gICAgICAgIHdpZHRoOiAxMDAlO1xuICAgICAgICBoZWlnaHQ6IGF1dG87XG4gICAgfVxuICAgIC5zbGlkZSB7IFxuICAgICAgICB3aWR0aDogMTAwJTtcbiAgICAgICAgaGVpZ2h0OiBhdXRvO1xuICAgICAgICBvYmplY3QtZml0OiBjb3ZlcjtcbiAgICB9XG4iXX0= */</style>
<script>
    import { sidebar } from '../store.js';
    // import { stores } from '@sapper/app';
    // const { preloading, page, session } = stores();

    export let path ='';
    
    let n = 0;
    for (let i=0; i < $sidebar.items.length; i++) {
        if (path.indexOf($sidebar.items[i]["path"]) >= 0) {
            n = i;
            break;
        }
    }
    
    

</script>