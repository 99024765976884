<script>
	export let status;
	export let error;

	const dev = undefined === 'development';
</script>

<style>h1, p {
  margin: 0 auto;
}

h1 {
  font-size: 2.8em;
  font-weight: 700;
  margin: 0 0 0.5em 0;
}

p {
  margin: 1em auto;
}

@media (min-width: 480px) {
  h1 {
    font-size: 4em;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9yb3V0ZXMvX2Vycm9yLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQztFQUNDLGNBQWM7QUFDZjs7QUFFQTtFQUNDLGdCQUFnQjtFQUNoQixnQkFBZ0I7RUFDaEIsbUJBQW1CO0FBQ3BCOztBQUVBO0VBQ0MsZ0JBQWdCO0FBQ2pCOztBQUVBO0VBQ0M7SUFDQyxjQUFjO0VBQ2Y7QUFDRCIsImZpbGUiOiJzcmMvcm91dGVzL19lcnJvci5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcblx0aDEsIHAge1xuXHRcdG1hcmdpbjogMCBhdXRvO1xuXHR9XG5cblx0aDEge1xuXHRcdGZvbnQtc2l6ZTogMi44ZW07XG5cdFx0Zm9udC13ZWlnaHQ6IDcwMDtcblx0XHRtYXJnaW46IDAgMCAwLjVlbSAwO1xuXHR9XG5cblx0cCB7XG5cdFx0bWFyZ2luOiAxZW0gYXV0bztcblx0fVxuXG5cdEBtZWRpYSAobWluLXdpZHRoOiA0ODBweCkge1xuXHRcdGgxIHtcblx0XHRcdGZvbnQtc2l6ZTogNGVtO1xuXHRcdH1cblx0fVxuIl19 */</style>

<svelte:head>
	<title>{status}</title>
</svelte:head>

<h1>{status}</h1>

<p>{error.message}</p>

{#if dev && error.stack}
	<pre>{error.stack}</pre>
{/if}
