<script>
	import { sidebar } from '../store.js';
	import SideBarToggle from './SideBarToggle.svelte';

	export let segment;

</script>

<style>a.button {
  font-size: 23px;
  text-transform: none;
  width: auto;
  font-family: 'Cuprum';
  color: #fff;
  margin-left: auto;
  margin-right: auto;
}

.block {
  padding-left: 10%;
  padding-right: 10%;
}

.toolbar div.menu {
  display: none;
}

@media (min-width: 1195px) {
  .toolbar div.menu {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (min-width: 768px) {
  .toolbar div.menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
}

   


/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL05hdi5zdmVsdGUiLCI8bm8gc291cmNlPiJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDSTtFQUNJLGVBQWU7RUFDZixvQkFBb0I7RUFDcEIsV0FBVztFQUNYLHFCQUFxQjtFQUNyQixXQUFXO0VBQ1gsaUJBQWlCO0VBQ2pCLGtCQUFrQjtBQUN0Qjs7QUFDQTtFQUNJLGlCQUFpQjtFQUNqQixrQkFBa0I7QUFDdEI7O0FBSUg7RUNqQkQsY0FBQTtBRG1CQzs7QUFHQTtFQUNDO0lDdkJGLHFCQUFBO0lBQUEsc0JBQUE7RUR5QkU7QUFDRDs7QUFFQTtFQUNDO0lDN0JGLGNBQUE7SUFBQSxvQkFBQTtJQUFBLDhCQUFBO0lBQUEsWUFBQTtFRCtCRTtBQUNEIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL05hdi5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgICBhLmJ1dHRvbiB7XG4gICAgICAgIGZvbnQtc2l6ZTogMjNweDtcbiAgICAgICAgdGV4dC10cmFuc2Zvcm06IG5vbmU7XG4gICAgICAgIHdpZHRoOiBhdXRvO1xuICAgICAgICBmb250LWZhbWlseTogJ0N1cHJ1bSc7XG4gICAgICAgIGNvbG9yOiAjZmZmO1xuICAgICAgICBtYXJnaW4tbGVmdDogYXV0bztcbiAgICAgICAgbWFyZ2luLXJpZ2h0OiBhdXRvO1xuICAgIH1cbiAgICAuYmxvY2sge1xuICAgICAgICBwYWRkaW5nLWxlZnQ6IDEwJTtcbiAgICAgICAgcGFkZGluZy1yaWdodDogMTAlO1xuICAgIH1cblxuXG5cblx0LnRvb2xiYXIgZGl2Lm1lbnUge1xuXHRcdEBhcHBseSBoaWRkZW47XG5cdH1cblxuXG5cdEBzY3JlZW4gbGcge1xuXHRcdC50b29sYmFyIGRpdi5tZW51IHtcblx0XHRcdEBhcHBseSAgcGwtMTAgcHItMTA7XG5cdFx0fVxuXHR9XG5cblx0QHNjcmVlbiBtZCB7XG5cdFx0LnRvb2xiYXIgZGl2Lm1lbnUge1xuXHRcdFx0QGFwcGx5IGZsZXggaXRlbXMtY2VudGVyIGp1c3RpZnktYXJvdW5kIHctZnVsbDtcblx0XHR9XG5cdH1cblxuICAgXG5cbiIsbnVsbF19 */</style>

<!--<div class="toolbar flex justify-start items-center bg-primary w-full h-12">-->
<div class="toolbar z-10 sm_menu:fixed md:flex justify-start items-center bg-primary w-full h-12">
	<!--<div class="toggle flex-initial md:hidden ml-5 mr-3">-->
	<div class="toggle flex-initial md:hidden ml-5 mr-3">
		<!--<SideBarToggle />//-->
	</div>
	<div class="text-white text-center flex-initial w-full md:hidden mt-3">Адвокат Смирнова Е.В.</div>
	<div class="menu">
		{#each $sidebar.items as {path, title, comp}}
			<a rel='prefetch' href="/{path}" class="flex-initial text-white">{title}</a>
		{/each}
	</div>
</div>


